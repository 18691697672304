import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import {
  IconButton, Tooltip, Avatar, ListItemAvatar, ListItemText, ListItemButton, Divider, Box, Fab,
} from '@mui/material';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import Battery60Icon from '@mui/icons-material/Battery60';
import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';
import Battery20Icon from '@mui/icons-material/Battery20';
import BatteryCharging20Icon from '@mui/icons-material/BatteryCharging20';
import ErrorIcon from '@mui/icons-material/Error';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import KeyIcon from '@mui/icons-material/Key';
// import KeyOffIcon from '@mui/icons-material/KeyOff';
import NavigationIcon from '@mui/icons-material/Navigation';
import moment from 'moment';
import { devicesActions } from '../store';
import {
  formatAlarm, formatBoolean, formatPercentage, formatStatus, getStatusColor,
} from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import { mapIconKey, mapIcons } from '../map/core/preloadImages';
import { useAdministrator } from '../common/util/permissions';
// import { ReactComponent as EngineIcon } from '../resources/images/data/engine.svg';
import { useAttributePreference } from '../common/util/preferences';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  linha: {
    '&.MuiDivider-root': {
      marginLeft: '0px',
      borderColor: '#f0f0f0',
    },
  },
  cor: {
    backgroundColor: 'rgb(0 0 0 / 5%)',
  },
  infoButton: {
    '&.MuiButtonBase-root-MuiFab-root.Mui-disabled': {
      backgroundColor: '#000',
    },
  },
  batteryText: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    lineHeight: '0.875rem',
  },
  block: {
    color: theme.palette.colors.vermelho,
  },
  positive: {
    color: theme.palette.colors.positive,
  },
  negrito: {
    fontWeight: 'bold',
  },
  medium: {
    color: theme.palette.colors.medium,
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  neutral: {
    color: theme.palette.colors.neutral,
  },
}));

const scrollBarCSS = `
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(205 220 57 / 50%);
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgb(205 220 57 / 90%);
  }
`;

const DeviceRow = ({ data, index, style }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslation();

  const admin = useAdministrator();

  const item = data[index];
  const position = useSelector((state) => state.session.positions[item.id]);

  const geofences = useSelector((state) => state.geofences.items);

  const devicePrimary = useAttributePreference('devicePrimary', 'name');
  const deviceSecondary = useAttributePreference('deviceSecondary', '');
  const deviceTertiary = useAttributePreference('deviceTertiary', '');
  // const deviceQuaternary = useAttributePreference('deviceQuaternary', '');

  const formatProperty = (key) => {
    if (key === 'geofenceIds') {
      const geofenceIds = item[key] || [];
      return geofenceIds
        .filter((id) => geofences.hasOwnProperty(id))
        .map((id) => geofences[id].name)
        .join(', ');
    }
    return item[key];
  };

  // const capitalized = deviceTertiary[0].toUpperCase() + deviceTertiary.substr(1);

  const secondaryText = () => {
    let status;
    if (item.status === 'online' || !item.lastUpdate) {
      status = formatStatus(item.status, t);
    } else {
      status = moment(item.lastUpdate).fromNow();
    }

    // const negrito = deviceTertiary.bold();

    return (
      <>
        <span className={classes[getStatusColor(item.status)]}>{status}</span>
        {deviceSecondary && item[deviceSecondary] && ` • ${formatProperty(deviceSecondary)} `}
        {deviceTertiary && item[deviceTertiary] && ` • ${formatProperty(deviceTertiary)} `}
      </>
    );
  };
  // editado
  return (
    <div style={style}>
      <style>{scrollBarCSS}</style>
      <ListItemButton
        key={item.id}
        onClick={() => dispatch(devicesActions.select(item.id))}
        disabled={!admin && item.disabled}
      >
        <ListItemAvatar>
          <Avatar sx={{ backgroundColor: '#263238' }}>
            <img className={classes.icon} src={mapIcons[mapIconKey(item.category)]} alt="" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={formatProperty(devicePrimary)}
          primaryTypographyProps={{ noWrap: true }}
          secondary={secondaryText()}
          secondaryTypographyProps={{ noWrap: true }}
          tertiary={deviceTertiary}
          tertiaryTypographyProps={{ noWrap: true }}
        />
        {position && (
          <>
            {position.attributes.hasOwnProperty('alarm') && (
              <Tooltip title={`${t('eventAlarm')}: ${formatAlarm(position.attributes.alarm, t)}`}>
                <IconButton size="small">
                  <ErrorIcon fontSize="small" className={classes.negative} />
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('ignition') && (
              <Tooltip arrow title={`${t('positionIgnition')}: ${formatBoolean(position.attributes.ignition, t)}`}>
                <Box sx={{ '& > :not(style)': { m: 0.3 } }}>
                  <Fab className={classes.cor} size="small" disabled aria-label="like">
                    {position.attributes.ignition ? (
                      <KeyIcon width={18} height={18} className={classes.positive} />
                    ) : (
                      <KeyIcon width={18} height={18} className={classes.neutral} />
                    )}
                  </Fab>
                </Box>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('motion') && (
              <Tooltip arrow title={`${t('positionMotion')}: ${formatBoolean(position.attributes.motion, t)}`}>
                <Box className={classes.infoButton} sx={{ '& > :not(style)': { m: 0.3 } }}>
                  <Fab className={classes.cor} size="small" disabled aria-label="like">
                    {position.attributes.motion ? (
                      <NavigationIcon width={18} height={18} className={classes.positive} />
                    ) : (
                      <NavigationIcon width={18} height={18} className={classes.neutral} />
                    )}
                  </Fab>
                </Box>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('blocked') && (
              <Tooltip arrow title={`${t('positionBlocked')}: ${formatBoolean(position.attributes.blocked, t)}`}>
                <Box className={classes.infoButton} sx={{ '& > :not(style)': { m: 0.3 } }}>
                  <Fab className={classes.cor} size="small" disabled aria-label="like">
                    {position.attributes.blocked ? (
                      <LockIcon width={18} height={18} className={classes.block} />
                    ) : (
                      <LockOpenIcon width={18} height={18} className={classes.neutral} />
                    )}
                  </Fab>
                </Box>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('batteryLevel') && (
              <Tooltip arrow title={`${t('positionBatteryLevel')}: ${formatPercentage(position.attributes.batteryLevel)}`}>
                <Box className={classes.infoButton} sx={{ '& > :not(style)': { m: 0.3, backgroundColor: '#000' } }}>
                  <Fab className={classes.cor} size="small" disabled aria-label="like">
                    {position.attributes.batteryLevel > 70 ? (
                      position.attributes.charge
                        ? (<BatteryChargingFullIcon fontSize="small" className={classes.positive} />)
                        : (<BatteryFullIcon fontSize="small" className={classes.positive} />)
                    ) : position.attributes.batteryLevel > 30 ? (
                      position.attributes.charge
                        ? (<BatteryCharging60Icon fontSize="small" className={classes.medium} />)
                        : (<Battery60Icon fontSize="small" className={classes.medium} />)
                    ) : (
                      position.attributes.charge
                        ? (<BatteryCharging20Icon fontSize="small" className={classes.negative} />)
                        : (<Battery20Icon fontSize="small" className={classes.negative} />)
                    )}
                  </Fab>
                </Box>
              </Tooltip>
            )}
          </>
        )}
      </ListItemButton>
      <Divider className={classes.linha} variant="inset" component="ul" />
    </div>
  );
};

export default DeviceRow;
