import React, { useState } from 'react';
import {
  Box, // Table, TableRow, TableCell, TableHead, TableBody,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';
import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useAdministrator } from '../common/util/permissions';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import CollectionFab from './components/CollectionFab';
import CollectionActions from './components/CollectionActions';
// import TableShimmer from '../common/components/TableShimmer';
import SearchHeader, { filterByKeyword } from './components/SearchHeader';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    border: '1px solid #ced7df',
    padding: theme.spacing(1),
    margin: '20px',
  },
  headerTable: {
    '&.MuiTable-root': {
      width: 'auto',
      margin: '15px',
    },
    '&.MuiTableHead-root': {
      backgroundColor: '#ced7df',
      border: '1px solid #ced7df',
    },
    '&.MuiTableCell-root': {
      border: '1px solid #ced7df',
    },
  },
  chip: {
    '&.MuiChip-root': {
      marginLeft: '15px',
    },
  },
  outline: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
  },
  colorCell: {
    '&.MuiTable-root': {
      width: 'auto',
      margin: '15px',
    },
    '&.MuiTableHead-root': {
      backgroundColor: '#ced7df',
      border: '1px solid #ced7df',
    },
    '&.MuiTableCell-root': {
      border: '1px solid #ced7df',
    },
    '&.MuiTableRow-root:hover': {
      backgroundColor: '#f5faff',
    },
  },
}));

const ComputedAttributesPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const administrator = useAdministrator();

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/attributes/computed');
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  const localeText = {
    // Root
    rootGridLabel: 'grid',
    noRowsLabel: 'Nenhum resultado encontrado',
    noResultsOverlayLabel: 'Nenhum resultado encontrado.',
    errorOverlayDefaultLabel: 'Ocorreu um erro.',

    // Density selector toolbar button text
    toolbarDensity: 'Densidade',
    toolbarDensityLabel: 'Densidade',
    toolbarDensityCompact: 'Compacta',
    toolbarDensityStandard: 'Padrão',
    toolbarDensityComfortable: 'Confortável',

    // labelDisplayedRows: '{from}-{to} de {count}',

    // Columns selector toolbar button text
    toolbarColumns: 'Colunas',
    toolbarColumnsLabel: 'Selecionar colunas',

    // Filters toolbar button text
    toolbarFilters: 'Filtros',
    toolbarFiltersLabel: 'Mostrar filtros',
    toolbarFiltersTooltipHide: 'Esconder filtros',
    toolbarFiltersTooltipShow: 'Mostrar filtros',
    toolbarFiltersTooltipActive: (count) => (count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`),

    // Sort toolbar button text
    toolbarSort: 'Ordenação',
    toolbarSortLabel: 'Ordenar',
    toolbarSortAscending: 'Ordenar crescente',
    toolbarSortDescending: 'Ordenar decrescente',
    toolbarSortUnsort: 'Remover ordenação',
    toolbarSortTooltipHide: 'Esconder ordenação',
    toolbarSortTooltipShow: 'Mostrar ordenação',

    // Column menu text
    columnMenuLabel: 'Menu',
    columnMenuShowColumns: 'Mostrar colunas',
    columnMenuFilter: 'Filtro',
    columnMenuHideColumn: 'Esconder',
    columnMenuUnsort: 'Remover ordenação',
    columnMenuSortAsc: 'Ordenar crescente',
    columnMenuSortDesc: 'Ordenar decrescente',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) => (count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`),
    columnHeaderFiltersLabel: 'Mostrar filtros',
    columnHeaderSortIconLabel: 'Ordenar',

    // Rows selected footer text
    footerRowSelected: (count) => (count !== 1
      ? `${count.toLocaleString()} linhas selecionadas`
      : `${count.toLocaleString()} linha selecionada`),

    // Total rows footer text
    footerTotalRows: 'Total de linhas:',
    footerPaginationRowsPerPage: 'Linhas por página:',
    footerPaginationOf: (de) => `de ${de}`,
    footerPaginationRowsPerPageOptions: [5, 10, 20],
    labelFooterPagination: (de) => `de ${de}`,
    labelFooterSelectedRows: (de) => `de ${de}`,

    footerPaginationLabel: ({ from, to, count }) => `${from}-${to} de ${count}`,
    // Traduções para o Pagination
    paginationLabelRowsPerPage: 'Linhas por página',
    paginationLabelDisplayedRows: '{from}-{to} de {count}',
    paginationPrevious: 'Anterior',
    paginationNext: 'Próximo',
    paginationFirst: 'Primeira página',
    paginationLast: 'Última página',
    paginationAriaLabelRowsPerPage: 'Linhas por página',

    // Text displayed for current page number.
    footerPaginationPage: 'Página:',
    // Text displayed for total number of pages.
    footerPaginationTotalPages: (totalPages) => `de ${totalPages}`,
    // Text displayed for the current visible rows displayed in the grid.
    // e.g. "1-10 of 100"
    footerPaginationDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count}`,
    // Traduções para o Toolbar
    columnMenuRemoveColumn: 'Remover coluna',
    // Traduções para o FilterPanel
    filterPanelAddFilter: 'Adicionar filtro',
    filterPanelDeleteIconLabel: 'Excluir',
    filterPanelOperators: 'Operadores',
    filterPanelOperatorAnd: 'E',
    filterPanelOperatorOr: 'Ou',
    filterPanelColumns: 'Colunas',
    filterPanelInputLabel: 'Valor',
    filterPanelInputPlaceholder: 'Filtrar valor',
    // Traduções para o FilterOperator
    filterOperatorContains: 'Contém',
    filterOperatorEquals: 'Igual a',
    filterOperatorStartsWith: 'Começa com',
    filterOperatorEndsWith: 'Termina com',
    filterOperatorIs: 'É',
    filterOperatorNot: 'Não é',
    filterOperatorAfter: 'Depois de',
    filterOperatorOnOrAfter: 'Em ou depois de',
    filterOperatorBefore: 'Antes de',
    filterOperatorOnOrBefore: 'Em ou antes de',
    filterOperatorIsEmpty: 'Está vazio',
    filterOperatorIsNotEmpty: 'Não está vazio',
    filterOperatorIsAnyOf: 'É algum dos',
    // Traduções para o FilterValue
    filterValueAny: 'Qualquer',
    filterValueTrue: 'Sim',
    filterValueFalse: 'Não',
    filterValueYes: 'Sim',
    filterValueNo: 'Não',

    // Pagination
    nextIconButtonText: 'Próximo',
    pagination: {
      labelRowsSelect: 'linhas',
      labelDisplayedRows: '{count} de {from}-{to}',
      nextIconButtonText: 'Próximo',
      lastIconButtonText: 'Último',
      firstIconButtonText: 'Primeiro',
      previousIconButtonText: 'Anterior',
    },

    // Rows per page menu
    footerRowsPerPage: 'Linhas por Página:',

    // Labels
    noResultsLabel: 'Nenhum resultado encontrado',

    // Density selector toolbar button text

    footer: {
      pagination: {
        // Texto exibido antes do número da página
        page: 'Página',
        // Texto exibido após o número total de páginas
        pageOf: 'de',
        // Texto exibido antes do número total de linhas
        rowsPerPage: 'Linhas por página:',
        // Opções de quantidade de linhas por página
        rowsPerPageOptions: [5, 10, 20, 50],
      },
    },

  };

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'sharedComputedAttributes']}>
      <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
      {/* <Table className={classes.headerTable}>
        <TableHead className={classes.headerTable}>
          <TableRow className={classes.headerTable}>
            <TableCell className={classes.headerTable}>{t('sharedDescription')}</TableCell>
            <TableCell className={classes.headerTable}>{t('sharedAttribute')}</TableCell>
            <TableCell className={classes.headerTable}>{t('sharedExpression')}</TableCell>
            <TableCell className={classes.headerTable}>{t('sharedType')}</TableCell>
            {administrator && <TableCell className={classes.columnAction} />}
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? items.filter(filterByKeyword(searchKeyword)).map((item) => (
            <TableRow className={classes.colorCell} key={item.id}>
              <TableCell className={classes.headerTable}>{item.description}</TableCell>
              <TableCell className={classes.headerTable}>{item.attribute}</TableCell>
              <TableCell className={classes.headerTable}>{item.expression}</TableCell>
              <TableCell className={classes.headerTable}>{item.type}</TableCell>
              {administrator && (
                <TableCell className={classes.columnAction} padding="none">
                  <CollectionActions itemId={item.id} editPath="/settings/attribute" endpoint="attributes/computed" setTimestamp={setTimestamp} />
                </TableCell>
              )}
            </TableRow>
          )) : (<TableShimmer columns={administrator ? 5 : 4} endAction={administrator} />)}
        </TableBody>
      </Table> */}
      <Box sx={{
        padding: 2,
        width: '100%',
        '& .super-app-theme--header': {
          backgroundColor: 'rgb(205 220 57 / 66%)',
          outline: 'none',
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-root .MuiDataGrid-iconSeparator': {
          color: 'darkgray',
        },
      }}
      >
        <DataGrid
          className={classes.outline}
          rows={items.filter(filterByKeyword(searchKeyword))}
          columns={[
            { field: 'id', headerName: 'ID', headerClassName: 'super-app-theme--header', flex: 1 },
            { field: 'description', headerName: t('sharedDescription'), headerClassName: 'super-app-theme--header', flex: 2 },
            { field: 'attribute', headerClassName: 'super-app-theme--header', headerName: t('sharedAttribute'), flex: 2 },
            { field: 'expression', headerClassName: 'super-app-theme--header', headerName: t('sharedExpression'), flex: 2 },
            { field: 'type', headerClassName: 'super-app-theme--header', headerName: t('sharedType'), flex: 2 },
            {
              field: 'actions',
              headerClassName: 'super-app-theme--header',
              headerName: t('acoesTitle'),
              sortable: false,
              flex: 2,
              disableColumnMenu: true,
              disableClickEventBubbling: true,
              renderCell: (params) => administrator && (
              <CollectionActions
                sx={{ outline: 'none' }}
                itemId={params.row.id}
                editPath="/settings/attribute"
                endpoint="attributes/computed"
                setTimestamp={setTimestamp}
              />
              ),
            },
          ]}
          loading={loading}
          pageSize={10}
          rowsPerPageOptions={[10]}
          autoHeight="true"
          autoWidth
          localeText={localeText}
          // paginationMode="server"
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
      <CollectionFab editPath="/settings/attribute" disabled={!administrator} />
    </PageLayout>
  );
};

export default ComputedAttributesPage;
