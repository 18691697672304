import React from 'react';
import {
  Divider, List, ListItemButton, ListItemIcon, ListItemText,
} from '@mui/material';
import { GoSettings } from 'react-icons/go';
import { AiOutlineAlert } from 'react-icons/ai';
import { VscAccount, VscFileCode } from 'react-icons/vsc';
import { RiSmartphoneLine, RiUser3Line, RiCodeSSlashFill } from 'react-icons/ri';
import { BsBoundingBoxCircles, BsGear, BsCalendar4Week } from 'react-icons/bs';
import { ImMakeGroup } from 'react-icons/im';
import { TbHeartRateMonitor, TbUsers } from 'react-icons/tb';

import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from '../../common/components/LocalizationProvider';
import {
  useAdministrator, useDeviceReadonly, useManager, useRestriction,
} from '../../common/util/permissions';
import useFeatures from '../../common/util/useFeatures';

const useStyles = makeStyles((theme) => ({
  icone: {
    marginBottom: '0',
    marginRight: '15px',
    color: '#fff',
    fontWeight: '1000',
  },
  icon: {
    width: theme.spacing(1),
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
}));

const MenuItem = ({
  title, link, icon, selected,
}) => (
  <ListItemButton sx={{ margin: '3px 15px 0 15px', borderRadius: '10px' }} key={link} component={Link} to={link} selected={selected}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} />
  </ListItemButton>
);
// editado
const SettingsMenu = () => {
  const t = useTranslation();
  const location = useLocation();

  const classes = useStyles({ });

  const readonly = useRestriction('readonly');
  const deviceReadonly = useDeviceReadonly();
  const admin = useAdministrator();
  const manager = useManager();
  const userId = useSelector((state) => state.session.user.id);

  const features = useFeatures();

  return (
    <>
      <List>
        <MenuItem
          title={t('sharedPreferences')}
          link="/settings/preferences"
          icon={<GoSettings size={23} className={classes.icone} />}
          selected={location.pathname === '/settings/preferences'}
        />
        {!readonly && (
          <>
            <MenuItem
              title={t('sharedNotifications')}
              link="/settings/notifications"
              icon={<AiOutlineAlert size={23} className={classes.icone} />}
              selected={location.pathname.startsWith('/settings/notification')}
            />
            <MenuItem
              title={t('settingsUser')}
              link={`/settings/user/${userId}`}
              icon={<VscAccount size={23} className={classes.icone} />}
              selected={location.pathname === `/settings/user/${userId}`}
            />
            {!deviceReadonly && (
              <MenuItem
                title={t('deviceTitle')}
                link="/settings/devices"
                icon={<RiSmartphoneLine size={23} className={classes.icone} />}
                selected={location.pathname.startsWith('/settings/device')}
              />
            )}
            <MenuItem
              title={t('sharedGeofences')}
              link="/geofences"
              icon={<BsBoundingBoxCircles size={23} className={classes.icone} />}
              selected={location.pathname.startsWith('/settings/geofence')}
            />
            {!features.disableGroups && (
              <MenuItem
                title={t('settingsGroups')}
                link="/settings/groups"
                icon={<ImMakeGroup size={23} className={classes.icone} />}
                selected={location.pathname.startsWith('/settings/group')}
              />
            )}
            {!features.disableDrivers && (
              <MenuItem
                title={t('sharedDrivers')}
                link="/settings/drivers"
                icon={<RiUser3Line size={23} className={classes.icone} />}
                selected={location.pathname.startsWith('/settings/driver')}
              />
            )}
            {!features.disableCalendars && (
              <MenuItem
                title={t('sharedCalendars')}
                link="/settings/calendars"
                icon={<BsCalendar4Week size={23} className={classes.icone} />}
                selected={location.pathname.startsWith('/settings/calendar')}
              />
            )}
            {!features.disableComputedAttributes && (
              <MenuItem
                title={t('sharedComputedAttributes')}
                link="/settings/attributes"
                icon={<RiCodeSSlashFill size={23} className={classes.icone} />}
                selected={location.pathname.startsWith('/settings/attribute')}
              />
            )}
            {!features.disableMaintenance && (
              <MenuItem
                title={t('sharedMaintenance')}
                link="/settings/maintenances"
                icon={<BsGear size={23} className={classes.icone} />}
                selected={location.pathname.startsWith('/settings/maintenance')}
              />
            )}
            <MenuItem
              title={t('sharedSavedCommands')}
              link="/settings/commands"
              icon={<VscFileCode size={23} className={classes.icone} />}
              selected={location.pathname.startsWith('/settings/command') && !location.pathname.startsWith('/settings/command-send')}
            />
          </>
        )}
      </List>
      {manager && (
        <>
          <Divider />
          <List>
            {admin && (
              <MenuItem
                title={t('settingsServer')}
                link="/settings/server"
                icon={<TbHeartRateMonitor size={23} className={classes.icone} />}
                selected={location.pathname === '/settings/server'}
              />
            )}
            <MenuItem
              title={t('settingsUsers')}
              link="/settings/users"
              icon={<TbUsers size={23} className={classes.icone} />}
              selected={location.pathname.startsWith('/settings/user') && location.pathname !== `/settings/user/${userId}`}
            />
          </List>
        </>
      )}
    </>
  );
};

export default SettingsMenu;
