import React, { useCallback, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { TbFenceOff, TbFence } from 'react-icons/tb';
import MapView from '../map/core/MapView';
import MapSelectedDevice from '../map/main/MapSelectedDevice';
import MapAccuracy from '../map/main/MapAccuracy';
import MapGeofence from '../map/MapGeofence';
import MapCurrentLocation from '../map/MapCurrentLocation';
import PoiMap from '../map/main/PoiMap';
import MapPadding from '../map/MapPadding';
import { devicesActions } from '../store';
import MapDefaultCamera from '../map/main/MapDefaultCamera';
import MapLiveRoutes from '../map/main/MapLiveRoutes';
import MapPositions from '../map/MapPositions';
import MapOverlay from '../map/overlay/MapOverlay';
import MapGeocoder from '../map/geocoder/MapGeocoder';
import MapScale from '../map/MapScale';
import MapNotification from '../map/notification/MapNotification';
import useFeatures from '../common/util/useFeatures';

const MainMap = ({ filteredPositions, selectedPosition, onEventsClick }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const eventsAvailable = useSelector((state) => !!state.events.items.length);

  const features = useFeatures();

  const onMarkerClick = useCallback((_, deviceId) => {
    dispatch(devicesActions.select(deviceId));
  }, [dispatch]);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandRetract = () => {
    if (isExpanded) {
      setIsExpanded(false);
      document.exitFullscreen();
    } else {
      setIsExpanded(true);
      document.documentElement.requestFullscreen();
    }
  };

  const buttonStyleDesktop = {
    '&.MuiButtonBase-root-MuiButton-root': {
      minWidth: '0px',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '29px',
    height: '29px',
    backgroundColor: 'white',
    // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    cursor: 'pointer',
    position: 'absolute',
    top: '12px',
    right: '2px',
    // border: '2px solid #cacdd1',
    boxShadow: '0 0 0 2px rgb(0 0 0 / 10%)',
    padding: 0,
  };

  const buttonStyleMobile = {
    '&.MuiButtonBase-root-MuiButton-root': {
      minWidth: '0px',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '29px',
    height: '29px',
    backgroundColor: 'white',
    // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    cursor: 'pointer',
    position: 'fixed',
    top: '319px',
    right: '10px',
    // border: '2px solid #cacdd1',
    boxShadow: '0 0 0 2px rgb(0 0 0 / 10%)',
    padding: 0,
  };

  // const iconStyle = {
  //   width: '16px',
  //   height: '16px',
  //   border: 'solid #000',
  //   borderWidth: '0 2px 2px 0',
  //   padding: '3px',
  // };

  const [showGeofences, setShowGeofences] = useState(false);
  const [setGeofences] = useState([]);

  const handleToggleGeofences = () => {
    setShowGeofences(!showGeofences);
  };

  useEffect(() => {
    // Simulating fetching geofences from an API
    const fetchGeofences = async () => {
      try {
        const response = await fetch('/api/geofences');
        const data = await response.json();
        setGeofences(data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    fetchGeofences();
  }, []);

  return (
    <>
      <MapView>
        <MapOverlay />
        {/* <div>
          <Button onClick={handleToggleGeofences}>
            {showGeofences ? 'Hide Geofences' : 'Show Geofences'}
          </Button>
          {showGeofences && (
            <ul>
              {geofences.map((geofence) => (
                <li key={geofence.id}><MapGeofence /></li>
              ))}
            </ul>
          )}
        </div> */}
        <MapAccuracy positions={filteredPositions} />
        <MapLiveRoutes />
        <MapPositions
          positions={filteredPositions}
          onClick={onMarkerClick}
          selectedPosition={selectedPosition}
          showStatus
        />
        <MapDefaultCamera />
        <MapSelectedDevice />
        <PoiMap />
      </MapView>
      <MapScale />
      <MapCurrentLocation />
      <MapGeocoder />
      {!features.disableEvents && (
        <MapNotification enabled={eventsAvailable} onClick={onEventsClick} />
      )}
      {desktop && (
        <MapPadding left={parseInt(theme.dimensions.drawerWidthDesktop, 10)} />
      )}
      {desktop && (
        <div style={{ position: 'absolute', top: '250px', right: '8px' }}>
          <Button sx={{ minWidth: '0px' }} style={buttonStyleDesktop} variant="contained" onClick={handleExpandRetract}>
            {isExpanded ? <FullscreenExitIcon style={{ color: '#000000' }} /> : <FullscreenIcon style={{ color: '#000000' }} />}
          </Button>
          <Button sx={{ minWidth: '0px', marginTop: '38px' }} style={buttonStyleDesktop} variant="contained" onClick={handleToggleGeofences}>
            {showGeofences ? <TbFence size={23} style={{ color: '#000000' }} /> : <TbFenceOff size={23} style={{ color: '#000000' }} />}
          </Button>
          {showGeofences && (
            <MapGeofence />
          )}
        </div>
      )}
      {!desktop && (
      <div style={{ position: 'absolute', top: '250px', right: '8px' }}>
        <Button sx={{ minWidth: '0px' }} style={buttonStyleMobile} variant="contained" onClick={handleExpandRetract}>
          {isExpanded ? <FullscreenExitIcon style={{ color: '#000000' }} /> : <FullscreenIcon style={{ color: '#000000' }} />}
        </Button>
        <Button sx={{ minWidth: '0px' }} style={buttonStyleMobile} variant="contained" onClick={handleExpandRetract}>
          {showGeofences ? <TbFence size={23} style={{ color: '#000000' }} /> : <TbFenceOff size={23} style={{ color: '#000000' }} />}
        </Button>
      </div>
      )}
    </>
  );
};

export default MainMap;
