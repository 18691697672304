import React, { useState } from 'react';
import {
  IconButton, /* Menu, MenuItem, */ useMediaQuery, useTheme, Button, Stack,
} from '@mui/material';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import RemoveDialog from '../../common/components/RemoveDialog';
// import { useTranslation } from '../../common/components/LocalizationProvider';

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
  },
  '&.MuiButtonBase-root-MuiButton-root': {
    backgroundColor: '#ff0000',
    color: '#f0f0f0',
  },
}));

const CollectionActions = ({
  itemId, editPath, endpoint, setTimestamp, customAction,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  // const t = useTranslation();

  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  const [setMenuAnchorEl] = useState(null);
  const [removing, setRemoving] = useState(false);

  const handleEdit = () => {
    navigate(`${editPath}/${itemId}`);
    setMenuAnchorEl(null);
  };

  const handleRemove = () => {
    setRemoving(true);
    setMenuAnchorEl(null);
  };

  const handleCustom = () => {
    customAction.handler(itemId);
    setMenuAnchorEl(null);
  };

  const handleRemoveResult = (removed) => {
    setRemoving(false);
    if (removed) {
      setTimestamp(Date.now());
    }
  };

  return (
    <>
      {phone ? (
        <div className={classes.row}>
          <Stack direction="row" spacing={1}>
            {customAction && (
            <IconButton size="small" onClick={handleCustom}>
              {customAction.icon}
            </IconButton>
            )}
            <Button size="small" onClick={handleEdit} variant="outlined" startIcon={<EditIcon />}>
              Editar
            </Button>
            {/* <IconButton size="small" onClick={handleEdit}>
            <EditIcon fontSize="small" />
          </IconButton> */}
            <Button size="small" onClick={handleRemove} variant="contained" startIcon={<DeleteIcon />}>
              Deletar
            </Button>
            {/* <IconButton size="small" onClick={handleRemove}>
            <DeleteIcon fontSize="small" />
          </IconButton> */}
          </Stack>
        </div>
      ) : (
        <div className={classes.row}>
          <Stack direction="row" spacing={1}>
            {customAction && (
              <IconButton size="small" onClick={handleCustom}>
                {customAction.icon}
              </IconButton>
            )}
            <Button size="small" onClick={handleEdit} variant="outlined" startIcon={<EditIcon />}>
              Editar
            </Button>
            {/* <IconButton size="small" onClick={handleEdit}>
              <EditIcon fontSize="small" />
            </IconButton> */}
            <Button size="small" onClick={handleRemove} variant="contained" startIcon={<DeleteIcon />}>
              Deletar
            </Button>
            {/* <IconButton size="small" onClick={handleRemove}>
              <DeleteIcon fontSize="small" />
            </IconButton> */}
          </Stack>
        </div>
      )}
      <RemoveDialog style={{ transform: 'none' }} open={removing} endpoint={endpoint} itemId={itemId} onResult={handleRemoveResult} />
    </>
  );
};

export default CollectionActions;
