import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import axios from 'axios';
// import useModal from 'use-modal-element';
import { useNavigate } from 'react-router-dom';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
import Draggable from 'react-draggable';
import Swal from 'sweetalert2';
/* import {
  Button, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css'; */

import {
  Card,
  Typography,
  CardActions,
  IconButton,
  Tooltip,
  Table, TableBody, TableCell, TableContainer, TableRow,
  Menu,
  MenuItem,
  Box,
  Tab,
  Grid,
  CardMedia,
  Tabs,
  Modal,
  Button,
  Chip,
  Paper,
  // Button,
  // Typography,
  // TextField,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
// import CloseIcon from '@mui/icons-material/Close';
// import ReplayIcon from '@mui/icons-material/Replay';
import PublishIcon from '@mui/icons-material/Publish';
// import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import CellTowerIcon from '@mui/icons-material/CellTower';

import RouteIcon from '@mui/icons-material/Route';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import GoogleIcon from '@mui/icons-material/Google';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockIcon from '@mui/icons-material/Lock';

import { HiOutlineKey } from 'react-icons/hi';
import { FiNavigation2 } from 'react-icons/fi';
import { BiCodeBlock, BiCar } from 'react-icons/bi';
import { RiSignalTowerLine } from 'react-icons/ri';
import { TbCalendarTime } from 'react-icons/tb';
import { BsSpeedometer2, BsPinMapFill } from 'react-icons/bs';
import { AiOutlineLock } from 'react-icons/ai';
import { MdOutlineSimCard, MdOutlinePin, MdOutlineFiberPin } from 'react-icons/md';
import { ImInfo } from 'react-icons/im';

// import PendingIcon from '@mui/icons-material/Pending';
// import showToast from 'show-toast';
// import { Tabs, useTabState, usePanelState } from '@bumaga/tabs';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  formatBoolean, formatSpeed, formatTime,
} from '../util/formatter';

import { useTranslation } from './LocalizationProvider';
import RemoveDialog from './RemoveDialog';
// import PositionValue from './PositionValue';
import { useDeviceReadonly, useRestriction, /* useManager, */ useAdministrator } from '../util/permissions';
// import usePositionAttributes from '../attributes/usePositionAttributes';
import { devicesActions } from '../../store';
import { useCatch, useCatchCallback } from '../../reactHelper';
import { useAttributePreference, usePreference } from '../util/preferences';

import { ReactComponent as Image } from '../../resources/images/semimg.svg';

// const Tab = ({ children }) => {
//   const { onClick } = useTabState();
//   return <Button onClick={onClick}>{children}</Button>;
// };

// const Panel = ({ children }) => {
//   const isActive = usePanelState();

//   return isActive ? <p>{children}</p> : null;
// };

const useStyles = makeStyles((theme) => ({
  grid: {
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  botaoBlock: {
    backgroundColor: 'red',
  },
  botaoUnblock: {
    backgroundColor: 'green',
  },
  card: {
    width: theme.dimensions.popupMaxWidth,
  },
  media: {
    height: theme.dimensions.popupImageHeight,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  mediaButton: {
    color: theme.palette.colors.white,
    mixBlendMode: 'difference',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 1, 0, 2),
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  icone: {
    marginBottom: '-7px',
    marginRight: '15px',
    color: '#7a8491',
    fontWeight: '1000',
  },
  texto: {
    color: '#7a8491',
  },
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  botao: {
    width: '48%',
  },
  modal: {
    textAlign: 'center',
    padding: '0',
    content: '',
    display: 'inline-block',
    height: '100%',
    verticalAlign: 'middle',
    marginRight: '-4px',
  },
  lastRow: {
    '& td': {
      borderBottom: 'none',
    },
  },
  table: {
    '& .MuiTableCell-sizeSmall': {
      paddingLeft: 0,
      marginLeft: 0,
      paddingRight: 0,
    },
    border: 'none',
  },
  padding: {
    '& .MuiTabPanel-root': {
      paddingLeft: theme.spacing(0),
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(2),
    },
  },
  cell: {
    borderColor: '#f0f0f0',
  },
  actions: {
    justifyContent: 'space-between',
  },
  root: ({ desktopPadding }) => ({
    position: 'fixed',
    zIndex: 5,
    left: '50%',
    [theme.breakpoints.up('md')]: {
      left: `calc(50% + ${desktopPadding} / 2)`,
      bottom: theme.spacing(6),
    },
    [theme.breakpoints.down('md')]: {
      left: '50%',
      bottom: `calc(${theme.spacing(6)} + ${theme.dimensions.bottomBarHeight}px)`,
    },
    transform: 'translateX(-50%)',
  }),
}));

// const StatusRow = ({ name, content }) => {
//   const classes = useStyles();

//   return (
//     <TableRow>
//       <TableCell className={classes.cell}>
//         <Typography variant="body2">{name}</Typography>
//       </TableCell>
//       <TableCell className={classes.cell}>
//         <Typography variant="body2" color="textSecondary">{content}</Typography>
//       </TableCell>
//     </TableRow>
//   );
// };
// editado
const StatusCard = ({ deviceId, position, onClose, disableActions, desktopPadding = 0 }) => {
  // const [Modal, toggleModal] = useModal();

  const classes = useStyles({ desktopPadding });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();

  const readonly = useRestriction('readonly');
  const deviceReadonly = useDeviceReadonly();

  // const manager = useManager();
  const admin = useAdministrator();

  const device = useSelector((state) => state.devices.items[deviceId]);

  const deviceImage = device?.attributes?.deviceImage;

  // const positionAttributes = usePositionAttributes(t);
  // const positionItems = useAttributePreference('positionItems', 'speed,ignition,blocked,motion,deviceTime,address');

  const [anchorEl, setAnchorEl] = useState(null);

  const [removing, setRemoving] = useState(false);

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRemove = useCatch(async (removed) => {
    if (removed) {
      const response = await fetch('/api/devices');
      if (response.ok) {
        dispatch(devicesActions.refresh(await response.json()));
      } else {
        throw Error(await response.text());
      }
    }
    setRemoving(false);
  });
  const handleGeofence = useCatchCallback(async () => {
    const newItem = {
      name: `Geocerca: (${device.name})`,
      area: `CIRCLE (${position.latitude} ${position.longitude}, 15)`,
    };
    const response = await fetch('/api/geofences', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newItem),
    });
    if (response.ok) {
      const item = await response.json();
      const permissionResponse = await fetch('/api/permissions', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ deviceId: position.deviceId, geofenceId: item.id }),
      });
      if (!permissionResponse.ok) {
        throw Error(await permissionResponse.text());
      }
      Swal.fire({
        position: 'middle-center',
        icon: 'success',
        title: 'SUCESSO!',
        text: `Geocerca "${device.name}" criada com sucesso!`,
        showConfirmButton: false,
        timer: 3500,
      });
      setTimeout(() => {
        window.location.reload('/');
      }, 5000);
      // navigate('./');
      // window.location.reload();
      // navigate(`/settings/geofence/${item.id}`);
    } else {
      throw Error(await response.text());
    }
  }, [navigate, position]);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 100);
  };

  const handleBlockMotor = async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/commands/send', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ type: 'engineStop', attributes: {}, deviceId }),
      });
      if (response.ok) {
        Swal.fire({
          position: 'middle-center',
          icon: 'success',
          title: 'SUCESSO!',
          text: 'Comando de BLOQUEIO enviado com sucesso!',
          showConfirmButton: false,
          timer: 3500,
        });
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      error(error);
    } finally {
      handleClose();
    }
  };

  const handleUnblockMotor = async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/commands/send', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ type: 'engineResume', attributes: {}, deviceId }),
      });
      if (response.ok) {
        Swal.fire({
          position: 'middle-center',
          icon: 'success',
          title: 'SUCESSO!',
          text: 'Comando de DESBLOQUEIO enviado com sucesso!',
          showConfirmButton: false,
          timer: 3500,
        });
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      Swal.fire({
        position: 'middle-center',
        icon: 'error',
        title: 'ERRO!',
        text: 'Não foi possível enviar o comando de DESBLOQUEIO',
        showConfirmButton: false,
        timer: 3500,
      });
    } finally {
      handleClose();
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const ConfirmModal = useMemo(() => (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Paper style={{
        padding: '20px', width: '100%', height: '100%', maxWidth: '400px', maxHeight: '165px', display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
      }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <h3>O que deseja fazer?</h3>
          <Button style={{ marginLeft: '20px', color: '#c1bcbc' }} onClick={handleClose}>
            Fechar
          </Button>
        </div>
        <br />
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button startIcon={<LockOutlinedIcon />} style={{ width: '49%' }} className={classes.botaoBlock} variant="contained" onClick={handleBlockMotor} disabled={loading}>
            Bloquear
          </Button>
          <Button startIcon={<LockOpenOutlinedIcon />} style={{ width: '49%' }} className={classes.botaoUnblock} variant="contained" onClick={handleUnblockMotor} disabled={loading}>
            Desbloquear
          </Button>
        </div>
      </Paper>
    </Modal>
  ), [open, loading]);

  const speedUnit = useAttributePreference('speedUnit');

  const hours12 = usePreference('twelveHourFormat');

  return (
    <div className={classes.root}>
      {device && (
        <Draggable
          handle={`.${classes.media}, .${classes.header}`}
        >
          <Card elevation={3} className={classes.card}>
            {ConfirmModal}
            <Box sx={{ width: '100%', typography: 'body1' }} className={classes.padding}>
              <TabContext value={value}>
                <Tabs
                  orientation="vertical"
                  variant="fullWidth"
                  indicatorColor="secondary"
                  textColor="inherit"
                  value={false}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: 'divider' }}
                  centered
                >
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <center>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <TabList onChange={handleChange} aria-label="tabs">
                          <Tab label={t('titleRastreador')} value="1" />
                          <Tab label={t('titleVeiculo')} value="2" />
                          {admin && (
                          <Tab label={t('titleChip')} value="3" />
                          )}
                        </TabList>

                        <Button size="large" onClick={onClose}>X</Button>
                      </div>
                    </center>
                  </Box>
                </Tabs>
                <TabPanel value="1">
                  {/* <div>
                    <Button onClick={handleBlockMotor} disabled={loading}>
                      Block Motor
                    </Button>
                    <Button onClick={handleUnblockMotor} disabled={loading}>
                      Unblock Motor
                    </Button>
                  </div> */}
                  <center>
                    <Chip label={device.name.toUpperCase()} sx={{ marginBottom: '10px', width: '93%' }} variant="outlined" />
                    <br />
                    {admin && (
                      <Chip label={device.uniqueId} sx={{ width: '93%' }} />
                    )}
                  </center>
                  <br />
                  {/* {position && (
                    <Table size="small" classes={{ root: classes.table }}>
                      <TableBody classes={classes.content}>
                        {positionItems.split(',').filter((key) => position.hasOwnProperty(key) || position.attributes.hasOwnProperty(key)).map((key) => (
                          <StatusRow
                            key={key}
                            name={positionAttributes[key].name}
                            content={(
                              <PositionValue
                                position={position}
                                property={position.hasOwnProperty(key) ? key : null}
                                attribute={position.hasOwnProperty(key) ? null : key}
                              />
                            )}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  )} */}
                  {position && (
                    <TableContainer>
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ width: '195px' }} className={classes.cell}>
                              <BsSpeedometer2 size={23} className={classes.icone} />
                              {t('positionSpeed')}
                            </TableCell>
                            <TableCell className={classes.cell}><span className={classes.texto}>{formatSpeed(position.speed, speedUnit, t)}</span></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.cell}>
                              <HiOutlineKey size={23} className={classes.icone} />
                              {t('positionIgnition')}
                            </TableCell>
                            <TableCell className={classes.cell}><span className={classes.texto}>{formatBoolean(position.attributes.ignition, t)}</span></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.cell}>
                              <FiNavigation2 size={23} className={classes.icone} />
                              {t('positionMotion')}
                            </TableCell>
                            <TableCell className={classes.cell}><span className={classes.texto}>{formatBoolean(position.attributes.motion, t)}</span></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.cell}>
                              <AiOutlineLock size={23} className={classes.icone} />
                              {t('positionBlocked')}
                            </TableCell>
                            <TableCell className={classes.cell}><span className={classes.texto}>{formatBoolean(position.attributes.blocked, t)}</span></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.cell}>
                              <BiCodeBlock size={23} className={classes.icone} />
                              {t('positionProtocol')}
                            </TableCell>
                            <TableCell className={classes.cell}><span className={classes.texto}>{position.protocol}</span></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.cell}>
                              <RiSignalTowerLine size={23} className={classes.icone} />
                              {t('positionValid')}
                            </TableCell>
                            <TableCell className={classes.cell}><span className={classes.texto}>{formatBoolean(position.valid, t)}</span></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.cell}>
                              <TbCalendarTime size={23} className={classes.icone} />
                              {t('positionDeviceTime')}
                            </TableCell>
                            <TableCell className={classes.cell}><span className={classes.texto}>{formatTime(position.fixTime, 'seconds', hours12)}</span></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.cell}>
                              <BsPinMapFill size={23} className={classes.icone} />
                              {t('positionAddress')}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {position.address ? (
                                <span className={classes.texto}>{position.address}</span>
                              ) : (
                                <span className={classes.texto}>Sem dados</span>
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </TabPanel>
                <TabPanel value="2">
                  {deviceImage ? (
                    <CardMedia className={classes.media} image={`/api/media/${device.uniqueId}/${deviceImage}`}> </CardMedia>
                  ) : (
                    <CardMedia style={{ justifyContent: 'center', alignItems: 'center' }}>
                      <center><Image /></center>
                    </CardMedia>
                  )}
                  <div>
                    <br />
                    <center>
                      <Grid spacing={0}>
                        <TableContainer>
                          <Table size="small">
                            <TableBody>
                              <TableRow>
                                <TableCell style={{ width: '195px' }} className={classes.cell}>
                                  <ImInfo size={23} className={classes.icone} />
                                  {t('marcaTitle')}
                                </TableCell>
                                <TableCell className={classes.cell}><span className={classes.texto}><Chip sx={{ width: '100%' }} label={device.marca} variant="outlined" /></span></TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{ width: '195px' }} className={classes.cell}>
                                  <BiCar size={23} className={classes.icone} />
                                  {t('modeloTitle')}
                                </TableCell>
                                <TableCell className={classes.cell}><span className={classes.texto}><Chip sx={{ width: '100%' }} label={device.modelo} variant="outlined" /></span></TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{ width: '195px' }} className={classes.cell}>
                                  <MdOutlinePin size={23} className={classes.icone} />
                                  {t('placaTitle')}
                                </TableCell>
                                <TableCell className={classes.cell}><span className={classes.texto}><Chip sx={{ width: '100%' }} label={device.placa} variant="outlined" /></span></TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </center>
                  </div>
                </TabPanel>
                {admin && (
                <TabPanel value="3">
                  <div>
                    <br />
                    <Grid spacing={0}>
                      <TableContainer>
                        <Table size="small">
                          <TableBody>
                            <TableRow>
                              <TableCell style={{ width: '195px' }} className={classes.cell}>
                                <MdOutlineFiberPin size={24} className={classes.icone} />
                                {t('iccidTitle')}
                              </TableCell>
                              <TableCell className={classes.cell}><span className={classes.texto}><Chip sx={{ width: '100%' }} label={device.iccid} variant="outlined" /></span></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{ width: '195px' }} className={classes.cell}>
                                <MdOutlineSimCard size={24} className={classes.icone} />
                                {t('numeroTitle')}
                              </TableCell>
                              <TableCell className={classes.cell}><span className={classes.texto}><Chip sx={{ width: '100%' }} label={device.phone} variant="outlined" /></span></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{ width: '195px' }} className={classes.cell}>
                                <CellTowerIcon size={23} className={classes.icone} />
                                {t('operadoraTitle')}
                              </TableCell>
                              <TableCell className={classes.cell}><span className={classes.texto}><Chip sx={{ width: '100%' }} label={device.operadora} variant="outlined" /></span></TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </div>
                </TabPanel>
                )}
              </TabContext>
            </Box>
            <CardActions classes={{ root: classes.actions }} disableSpacing>
              <Tooltip title={t('sharedInfoTitle')} arrow placement="bottom">
                <IconButton color="primary" onClick={() => navigate(`/position/${position.id}`)} disabled={!position}>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('bloqueioTitle')} arrow placement="bottom">
                <IconButton className={classes.negative} onClick={handleClickOpen} disabled={!position}>
                  <LockIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('reportReplay')} arrow placement="bottom">
                <IconButton
                  onClick={() => navigate('/replay')}
                  disabled={disableActions || !position}
                >
                  <RouteIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('titleCommands')} arrow placement="bottom">
                <IconButton
                  onClick={() => navigate(`/settings/command-send/${deviceId}`)}
                  disabled={disableActions || readonly}
                >
                  <PublishIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('sharedEdit')} arrow placement="bottom">
                <IconButton
                  onClick={() => navigate(`/settings/device/${deviceId}`)}
                  disabled={disableActions || deviceReadonly}
                >
                  <AppSettingsAltIcon />
                </IconButton>
              </Tooltip>
              {position && (
              <Tooltip title={t('sharedCreateGeofence')} arrow placement="bottom">
                <IconButton onClick={handleGeofence}><RadioButtonCheckedIcon /></IconButton>
              </Tooltip>
              )}
              {position && (
              <Tooltip title={t('linkStreetView')} arrow placement="bottom">
                <IconButton component="a" target="_blank" href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${position.latitude}%2C${position.longitude}&heading=${position.course}`}><ShareLocationIcon /></IconButton>
              </Tooltip>
              )}
              {position && (
              <Tooltip title={t('linkGoogleMaps')} arrow placement="bottom">
                <IconButton component="a" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${position.latitude}%2C${position.longitude}`}><GoogleIcon /></IconButton>
              </Tooltip>
              )}
              <Tooltip title={t('titleDelete')} arrow placement="bottom">
                <IconButton
                  onClick={() => setRemoving(true)}
                  disabled={disableActions || deviceReadonly}
                  className={classes.primary}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </CardActions>
          </Card>
        </Draggable>
      )}
      {position && (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={() => navigate(`/position/${position.id}`)}><Typography color="secondary">{t('sharedShowDetails')}</Typography></MenuItem>
        </Menu>
      )}
      <RemoveDialog
        open={removing}
        endpoint="devices"
        itemId={deviceId}
        onResult={(removed) => handleRemove(removed)}
      />
    </div>
  );
};

export default StatusCard;
