import React, { useState } from 'react';
import {
  Box, // Table, TableRow, TableCell, TableHead, TableBody,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';
import { useEffectAsync } from '../reactHelper';
import usePositionAttributes from '../common/attributes/usePositionAttributes';
import { formatDistance, formatSpeed } from '../common/util/formatter';
import { useAttributePreference } from '../common/util/preferences';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import CollectionFab from './components/CollectionFab';
import CollectionActions from './components/CollectionActions';
// import TableShimmer from '../common/components/TableShimmer';
import SearchHeader, { filterByKeyword } from './components/SearchHeader';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    border: '1px solid #ced7df',
    padding: theme.spacing(1),
    margin: '20px',
  },
  headerTable: {
    '&.MuiTable-root': {
      width: 'auto',
      margin: '15px',
    },
    '&.MuiTableHead-root': {
      backgroundColor: '#ced7df',
      border: '1px solid #ced7df',
    },
    '&.MuiTableCell-root': {
      border: '1px solid #ced7df',
    },
  },
  chip: {
    '&.MuiChip-root': {
      marginLeft: '15px',
    },
  },
  outline: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
  },
  colorCell: {
    '&.MuiTable-root': {
      width: 'auto',
      margin: '15px',
    },
    '&.MuiTableHead-root': {
      backgroundColor: '#ced7df',
      border: '1px solid #ced7df',
    },
    '&.MuiTableCell-root': {
      border: '1px solid #ced7df',
    },
    '&.MuiTableRow-root:hover': {
      backgroundColor: '#f5faff',
    },
  },
}));

const MaintenacesPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const positionAttributes = usePositionAttributes(t);

  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const speedUnit = useAttributePreference('speedUnit');
  const distanceUnit = useAttributePreference('distanceUnit');

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/maintenance');
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  const convertAttribute = (key, value) => {
    const attribute = positionAttributes[key];
    if (attribute && attribute.dataType) {
      switch (attribute.dataType) {
        case 'speed':
          return formatSpeed(value, speedUnit, t);
        case 'distance':
          return formatDistance(value, distanceUnit, t);
        default:
          return value;
      }
    }

    return value;
  };

  const filteredItems = !loading && Array.isArray(items) ? items.filter((item) => filterByKeyword(searchKeyword)(item)) : [];

  const handleEdit = (id) => {
    // Redirect to the edit page for the selected device
    // eslint-disable-next-line no-restricted-globals
    history.push(`/settings/maintenance/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      // Make a DELETE request to delete the device
      const response = await fetch(`/api/maintenances/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        // If the delete was successful, update the timestamp to trigger a re-render of the data
        setTimestamp(Date.now());
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', sortable: true, width: 90, headerClassName: 'super-app-theme--header' },
    { field: 'name', headerName: t('sharedName'), sortable: true, flex: 2, headerClassName: 'super-app-theme--header' },
    { field: 'type', headerName: t('sharedType'), sortable: true, flex: 2, headerClassName: 'super-app-theme--header' },
    { field: 'itemStart', headerName: t('maintenanceStart'), sortable: true, flex: 2, headerClassName: 'super-app-theme--header' },
    { field: 'itemPeriod', headerName: t('maintenancePeriod'), sortable: true, flex: 2, headerClassName: 'super-app-theme--header' },
    {
      field: 'actions',
      sortable: false,
      width: 250,
      headerName: t('acoesTitle'),
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        const { itemId } = params.value;
        return (
          <CollectionActions
            itemId={itemId}
            editPath="/settings/maintenance"
            endpoint="maintenance"
            setTimestamp={setTimestamp}
            onEdit={() => handleEdit(itemId)}
            onDelete={() => handleDelete(itemId)}
          />
        );
      },
    },
  ];

  const rows = filteredItems.map((item) => ({
    id: item.id,
    name: item.name,
    type: item.type,
    itemStart: convertAttribute(item.type, item.start),
    itemPeriod: convertAttribute(item.type, item.period),
    actions: {
      itemId: item.id,
      editPath: '/settings/maintenance',
      endpoint: 'maintenances',
      setTimestamp,
      onEdit: () => handleEdit(item.id),
      onDelete: () => handleDelete(item.id),
    },
  }));

  const localeText = {
    // Root
    rootGridLabel: 'grid',
    noRowsLabel: 'Nenhum resultado encontrado',
    noResultsOverlayLabel: 'Nenhum resultado encontrado.',
    errorOverlayDefaultLabel: 'Ocorreu um erro.',

    // Density selector toolbar button text
    toolbarDensity: 'Densidade',
    toolbarDensityLabel: 'Densidade',
    toolbarDensityCompact: 'Compacta',
    toolbarDensityStandard: 'Padrão',
    toolbarDensityComfortable: 'Confortável',

    // labelDisplayedRows: '{from}-{to} de {count}',

    // Columns selector toolbar button text
    toolbarColumns: 'Colunas',
    toolbarColumnsLabel: 'Selecionar colunas',

    // Filters toolbar button text
    toolbarFilters: 'Filtros',
    toolbarFiltersLabel: 'Mostrar filtros',
    toolbarFiltersTooltipHide: 'Esconder filtros',
    toolbarFiltersTooltipShow: 'Mostrar filtros',
    toolbarFiltersTooltipActive: (count) => (count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`),

    // Sort toolbar button text
    toolbarSort: 'Ordenação',
    toolbarSortLabel: 'Ordenar',
    toolbarSortAscending: 'Ordenar crescente',
    toolbarSortDescending: 'Ordenar decrescente',
    toolbarSortUnsort: 'Remover ordenação',
    toolbarSortTooltipHide: 'Esconder ordenação',
    toolbarSortTooltipShow: 'Mostrar ordenação',

    // Column menu text
    columnMenuLabel: 'Menu',
    columnMenuShowColumns: 'Mostrar colunas',
    columnMenuFilter: 'Filtro',
    columnMenuHideColumn: 'Esconder',
    columnMenuUnsort: 'Remover ordenação',
    columnMenuSortAsc: 'Ordenar crescente',
    columnMenuSortDesc: 'Ordenar decrescente',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) => (count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`),
    columnHeaderFiltersLabel: 'Mostrar filtros',
    columnHeaderSortIconLabel: 'Ordenar',

    // Rows selected footer text
    footerRowSelected: (count) => (count !== 1
      ? `${count.toLocaleString()} linhas selecionadas`
      : `${count.toLocaleString()} linha selecionada`),

    // Total rows footer text
    footerTotalRows: 'Total de linhas:',
    footerPaginationRowsPerPage: 'Linhas por página:',
    footerPaginationOf: (de) => `de ${de}`,
    footerPaginationRowsPerPageOptions: [5, 10, 20],
    labelFooterPagination: (de) => `de ${de}`,
    labelFooterSelectedRows: (de) => `de ${de}`,

    footerPaginationLabel: ({ from, to, count }) => `${from}-${to} de ${count}`,
    // Traduções para o Pagination
    paginationLabelRowsPerPage: 'Linhas por página',
    paginationLabelDisplayedRows: '{from}-{to} de {count}',
    paginationPrevious: 'Anterior',
    paginationNext: 'Próximo',
    paginationFirst: 'Primeira página',
    paginationLast: 'Última página',
    paginationAriaLabelRowsPerPage: 'Linhas por página',

    // Text displayed for current page number.
    footerPaginationPage: 'Página:',
    // Text displayed for total number of pages.
    footerPaginationTotalPages: (totalPages) => `de ${totalPages}`,
    // Text displayed for the current visible rows displayed in the grid.
    // e.g. "1-10 of 100"
    footerPaginationDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count}`,
    // Traduções para o Toolbar
    columnMenuRemoveColumn: 'Remover coluna',
    // Traduções para o FilterPanel
    filterPanelAddFilter: 'Adicionar filtro',
    filterPanelDeleteIconLabel: 'Excluir',
    filterPanelOperators: 'Operadores',
    filterPanelOperatorAnd: 'E',
    filterPanelOperatorOr: 'Ou',
    filterPanelColumns: 'Colunas',
    filterPanelInputLabel: 'Valor',
    filterPanelInputPlaceholder: 'Filtrar valor',
    // Traduções para o FilterOperator
    filterOperatorContains: 'Contém',
    filterOperatorEquals: 'Igual a',
    filterOperatorStartsWith: 'Começa com',
    filterOperatorEndsWith: 'Termina com',
    filterOperatorIs: 'É',
    filterOperatorNot: 'Não é',
    filterOperatorAfter: 'Depois de',
    filterOperatorOnOrAfter: 'Em ou depois de',
    filterOperatorBefore: 'Antes de',
    filterOperatorOnOrBefore: 'Em ou antes de',
    filterOperatorIsEmpty: 'Está vazio',
    filterOperatorIsNotEmpty: 'Não está vazio',
    filterOperatorIsAnyOf: 'É algum dos',
    // Traduções para o FilterValue
    filterValueAny: 'Qualquer',
    filterValueTrue: 'Sim',
    filterValueFalse: 'Não',
    filterValueYes: 'Sim',
    filterValueNo: 'Não',

    // Pagination
    nextIconButtonText: 'Próximo',
    pagination: {
      labelRowsSelect: 'linhas',
      labelDisplayedRows: '{count} de {from}-{to}',
      nextIconButtonText: 'Próximo',
      lastIconButtonText: 'Último',
      firstIconButtonText: 'Primeiro',
      previousIconButtonText: 'Anterior',
    },

    // Rows per page menu
    footerRowsPerPage: 'Linhas por Página:',

    // Labels
    noResultsLabel: 'Nenhum resultado encontrado',

    // Density selector toolbar button text

    footer: {
      pagination: {
        // Texto exibido antes do número da página
        page: 'Página',
        // Texto exibido após o número total de páginas
        pageOf: 'de',
        // Texto exibido antes do número total de linhas
        rowsPerPage: 'Linhas por página:',
        // Opções de quantidade de linhas por página
        rowsPerPageOptions: [5, 10, 20, 50],
      },
    },

  };

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'sharedMaintenance']}>
      <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
      {/* <Table className={classes.headerTable}>
        <TableHead className={classes.headerTable}>
          <TableRow className={classes.headerTable}>
            <TableCell className={classes.headerTable}>{t('sharedName')}</TableCell>
            <TableCell className={classes.headerTable}>{t('sharedType')}</TableCell>
            <TableCell className={classes.headerTable}>{t('maintenanceStart')}</TableCell>
            <TableCell className={classes.headerTable}>{t('maintenancePeriod')}</TableCell>
            <TableCell className={classes.columnAction} />
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? items.filter(filterByKeyword(searchKeyword)).map((item) => (
            <TableRow className={classes.colorCell} key={item.id}>
              <TableCell className={classes.headerTable}>{item.name}</TableCell>
              <TableCell className={classes.headerTable}>{item.type}</TableCell>
              <TableCell className={classes.headerTable}>{convertAttribute(item.type, item.start)}</TableCell>
              <TableCell className={classes.headerTable}>{convertAttribute(item.type, item.period)}</TableCell>
              <TableCell className={classes.columnAction} padding="none">
                <CollectionActions itemId={item.id} editPath="/settings/maintenance" endpoint="maintenance" setTimestamp={setTimestamp} />
              </TableCell>
            </TableRow>
          )) : (<TableShimmer columns={5} endAction />)}
        </TableBody>
      </Table> */}
      <Box sx={{
        padding: 2,
        width: '100%',
        '& .super-app-theme--header': {
          backgroundColor: 'rgb(205 220 57 / 66%)',
          outline: 'none',
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-root .MuiDataGrid-iconSeparator': {
          color: 'darkgray',
        },
      }}
      >
        <DataGrid
          className={classes.outline}
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          autoHeight="true"
          loading={loading}
          autoWidth
          // paginationMode="server"
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          cellClassName={classes.cell}
          localeText={localeText}
        />
      </Box>
      <CollectionFab editPath="/settings/maintenance" />
    </PageLayout>
  );
};

export default MaintenacesPage;
